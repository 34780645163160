import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  preserveAspectRatio: "xMinYMid meet",
  viewBox: "0 0 176 127"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M32.813 102.129h18.515c4.453 0 7.441-2.988 7.441-7.207V80.859c0-4.218-2.988-7.207-7.44-7.207H32.811c-4.453 0-7.44 2.989-7.44 7.207v14.063c0 4.219 2.987 7.207 7.44 7.207ZM5.39 45.762h165.82V28.77H5.391Zm17.402 81.797h130.781c15.176 0 22.852-7.676 22.852-22.618V22.793c0-14.941-7.676-22.617-22.852-22.617H22.793C7.676.176 0 7.793 0 22.793v82.148c0 15 7.676 22.618 22.793 22.618Zm.234-10.43c-8.203 0-12.656-4.336-12.656-12.715V23.32c0-8.437 4.453-12.773 12.656-12.773h130.371c8.028 0 12.598 4.336 12.598 12.773v81.094c0 8.379-4.57 12.715-12.598 12.715Z"
    }, null, -1)
  ])))
}
export default { render: render }