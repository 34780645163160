import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 163 164"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M46.934 116.367c-2.051-2.168-5.743-2.168-7.91 0l-15 14.824c-2.169 2.168-2.169 5.801-.06 7.969 2.169 2.168 5.86 2.227 8.028.059l14.942-14.942c2.168-2.109 2.168-5.742 0-7.91ZM32.52 81.68c0-3.047-2.579-5.684-5.626-5.684H5.684C2.578 75.996 0 78.633 0 81.68s2.578 5.625 5.684 5.625h21.21c3.047 0 5.626-2.578 5.626-5.625Zm13.828-35.215c2.168-2.11 2.168-5.742 0-7.91l-14.825-15c-2.168-2.168-5.8-2.227-7.968-.059-2.168 2.168-2.168 5.8 0 7.969l14.824 15c2.168 2.168 5.8 2.168 7.969 0ZM81.62 32.519c3.047 0 5.684-2.636 5.684-5.683V5.684C87.305 2.637 84.668 0 81.62 0s-5.625 2.637-5.625 5.684v21.152c0 3.047 2.578 5.684 5.625 5.684Zm34.629 14.532c2.168 2.168 5.801 2.168 7.969 0l15-14.942c2.168-2.168 2.168-5.859 0-8.027-2.168-2.168-5.801-2.168-7.969 0l-15 15.059c-2.168 2.168-2.168 5.742 0 7.91ZM130.78 81.68c0 3.047 2.637 5.625 5.684 5.625h21.152c3.047 0 5.684-2.578 5.684-5.625s-2.637-5.684-5.684-5.684h-21.152c-3.047 0-5.684 2.637-5.684 5.684Zm-55.253 50.508c-.058 2.695 3.047 3.574 4.805 1.874l11.719-11.835 13.301 32.753c.586 1.348 1.992 1.993 3.281 1.465l6.855-2.754c1.289-.527 1.758-2.05 1.172-3.339l-13.887-32.168 16.817-.352c2.637-.117 3.926-2.695 2.051-4.629l-41.309-43.3c-1.758-1.817-4.512-.762-4.57 1.816Z"
    }, null, -1)
  ])))
}
export default { render: render }