<template>
  <div id="app antialiased">
    <lazy-l-header />
    <main>
      <section id="hero">
        <div
          class="from-gradient-1 to-gradient-2 rounded-[20px] bg-gradient-to-l pb-10 pt-16 lg:pl-10 2xl:mx-[60px]">
          <div class="container">
            <div class="flex flex-row">
              <div class="flex flex-wrap">
                <div class="w-full px-4 lg:w-1/2">
                  <lazy-l-h1>
                    {{ content.hero.headline }}
                  </lazy-l-h1>
                  <p class="prose text-deep-purple mb-8 text-xl font-medium tracking-tight">
                    {{ content.hero.lead }}
                  </p>
                  <div
                    class="mx-auto flex w-full flex-col gap-y-2 md:w-96 lg:w-full lg:flex-row lg:gap-y-0 lg:space-x-3">
                    <lazy-l-button
                      variant="primary"
                      size="xl"
                      class="mb-2 lg:max-w-[200px]"
                      @click="navigateTo('/signup')">
                      Sign up free!
                    </lazy-l-button>
                    <lazy-l-button
                      class="glightbox lg:max-w-xs"
                      variant="secondary"
                      size="xl"
                      @click="sendGtmEvent">
                      Watch one minute explainer
                    </lazy-l-button>
                  </div>
                </div>
                <div class="flex justify-center lg:w-1/2">
                  <nuxt-img
                    alt="Team of people working together"
                    provider="cloudinary"
                    src="/hero/team_ujyxxf.webp"
                    width="450px"
                    height="517"
                    class="hidden object-scale-down lg:block lg:max-w-[400px] xl:max-w-[450px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="who-uses-us">
        <div class="mt-5">
          <div class="container">
            <l-h3>Trusted by hundreds of businesses</l-h3>
            <l-logo-scroll />
          </div>
        </div>
      </section>
      <section id="features">
        <div class="mb-10">
          <div class="container">
            <lazy-l-feat
              v-for="(feature, i) in content.features"
              :key="i"
              class="mx-auto py-5 md:gap-10 md:px-2 lg:px-0"
              :order-first="i % 2 === 1">
              <template #image>
                <nuxt-img
                  loading="lazy"
                  :alt="feature.headline"
                  provider="cloudinary"
                  :src="feature.cloudinarypath"
                  :width="feature.width"
                  :height="feature.height" />
              </template>
              <template #headline>
                <l-h2 class="prose prose-2xl font-semibold lg:mb-4 lg:text-4xl lg:font-bold">
                  {{ feature.headline }}
                </l-h2>
              </template>
              <template #lead>
                <p class="prose prose-lg">
                  {{ feature.text }}
                </p>
              </template>
            </lazy-l-feat>
          </div>
        </div>
      </section>
      <section id="how-it-works">
        <div v-motion-slide-visible-once-bottom class="container">
          <l-h3>How it works</l-h3>
          <ul class="mb-10 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            <lazy-l-card :icon="CursorIcon" title="Create an account">
              Answer a few quick questions about you and your company; no commitment required up
              front.
            </lazy-l-card>
            <lazy-l-card :icon="CreditCardIcon" title="Buy seats">
              Buy seats for you and each member of your team. You can always buy more later!
            </lazy-l-card>
            <lazy-l-card :icon="EnvelopeIcon" title="Invite your team">
              Enter or import your team roster and we'll handle the rest.
            </lazy-l-card>
            <lazy-l-card :icon="GraduationCapIcon" title="Take course">
              Each team member takes the training course, completes a quiz, and receives a
              certificate. You can see them all in your panel.
            </lazy-l-card>
          </ul>
        </div>
      </section>
      <client-only>
        <section id="competitive-pricing">
          <div v-motion-slide-visible-once-bottom class="container">
            <l-h3>Simple pricing</l-h3>
            <div class="flex justify-center">
              <p class="prose text-lg">See how we compare to our competitors.</p>
            </div>
            <lazy-l-section-pricing class="mx-auto max-w-[450px] lg:hidden" />
            <div class="hidden lg:block">
              <div class="mx-auto mb-10 grid grid-cols-3 gap-3 text-center lg:gap-8">
                <div
                  v-for="(comp, i) in content.competition"
                  :key="i"
                  class="mt-10 rounded-xl p-2 pb-10 ring-1 ring-gray-200"
                  :class="{
                    'ring-2 ring-indigo-600': i === 1,
                  }">
                  <div v-if="comp.company == 'HIPAATraining'">
                    <h-i-p-a-a-training-logo class="mx-auto mt-5 h-7" />
                  </div>
                  <div v-else-if="comp.company == 'Accountable'">
                    <accountable-logo class="mx-auto mt-5 h-7" />
                  </div>
                  <div v-else class="text-primary prose mt-2 text-xl font-bold tracking-tight">
                    <span>TeachMeHIPAA</span>
                  </div>
                  <div class="flex flex-col justify-center">
                    <div class="mx-auto">
                      <p class="mb-3 mt-6 flex items-baseline gap-x-1">
                        <span class="prose text-3xl font-bold tracking-tight text-gray-900">{{
                          comp.price
                        }}</span>
                        <span class="prose text-sm font-semibold leading-6 text-gray-600"
                          >/ {{ comp.divisor }}</span
                        >
                      </p>
                    </div>
                    <ul>
                      <li
                        v-for="(feature, ii) in comp.features"
                        :key="ii"
                        class="prose text-md mt-2 flex flex-row px-2 text-left font-semibold">
                        <check-icon
                          class="mr-2 h-5 w-4 flex-none text-indigo-600"
                          aria-hidden="true" />
                        {{ feature.value }}
                      </li>
                      <li v-if="comp.company == 'TeachMeHIPAA'">
                        <div class="mx-auto mb-2 mt-10 flex justify-center">
                          <l-button
                            variant="secondary"
                            size="xl"
                            class="w-5/6"
                            @click="navigateTo('/signup')">
                            Sign up
                          </l-button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </client-only>
      <section id="testimonials">
        <div class="md:hidden">
          <l-h3>What our customers say</l-h3>
          <div class="container">
            <div>
              <div class="mt-5 flex items-start rounded-2xl bg-gray-900 p-10 text-white shadow-sm">
                <swiper
                  class="my-5"
                  :grab-cursor="true"
                  :effect="'creative'"
                  :creative-effect="{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ['100%', 0, 0],
                    },
                  }"
                  :modules="modules">
                  <swiper-slide v-for="(testimonial, i) in content.testimonials" :key="i">
                    <l-testimonial :review="testimonial" />
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden h-[550px] bg-gray-900 md:block">
          <div class="container">
            <div class="p-5 pt-10">
              <l-h3 class="text-white">What our customers say</l-h3>
            </div>
            <div class="flex content-center justify-center overflow-hidden align-middle">
              <splide
                :options="{
                  arrows: false,
                  autoplay: true,
                  height: '450px',
                  interval: 6000,
                  perPage: 1,
                  type: 'loop',
                }">
                <splide-slide v-for="(testimonial, i) in content.testimonials" :key="i">
                  <div
                    v-motion-slide-visible-right
                    class="mx-auto flex max-w-2xl flex-col items-center">
                    <figure class="mx-auto mt-10">
                      <blockquote class="prose text-3xl font-light leading-10 text-white">
                        <p>{{ testimonial.review }}</p>
                      </blockquote>
                      <figcaption class="mt-10 flex items-center gap-x-6">
                        <div class="text-base">
                          <div class="font-semibold text-white">{{ testimonial.name }}</div>
                          <div class="mt-1 text-gray-400">{{ testimonial.title }}</div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </splide-slide>
              </splide>
            </div>
          </div>
        </div>
      </section>
      <client-only>
        <section id="faq">
          <div v-motion-slide-visible-once-bottom class="container">
            <div class="mx-auto my-10 flex flex-col">
              <l-h3>Frequently asked questions</l-h3>
              <div>
                <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
                  <disclosure
                    v-for="faq in content.faqs"
                    :key="faq.question"
                    v-slot="{ open }"
                    as="div"
                    class="pt-6">
                    <dt>
                      <disclosure-button
                        class="flex w-full items-start justify-between text-left text-gray-900">
                        <span
                          class="text-lg font-light leading-7"
                          :class="{
                            'text-primary font-semibold': open,
                          }"
                          >{{ faq.question }}</span
                        >
                        <span class="ml-6 flex h-7 items-center">
                          <plus-icon v-if="!open" class="text-primary h-6 w-6" aria-hidden="true" />
                          <minus-icon v-else class="h-6 w-6" aria-hidden="true" />
                        </span>
                      </disclosure-button>
                    </dt>
                    <disclosure-panel as="dd" class="mt-2 pr-12">
                      <p class="prose text-lg font-light leading-7 text-gray-900">
                        {{ faq.answer }}
                      </p>
                    </disclosure-panel>
                  </disclosure>
                </dl>
              </div>
            </div>
          </div>
        </section>
      </client-only>
      <lazy-l-blog />
    </main>
    <footer id="footer" v-motion-slide-visible-once-bottom>
      <lazy-l-footer />
    </footer>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

import { EffectCreative } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import AccountableLogo from "~/assets/images/accountable.svg?component";
import HIPAATrainingLogo from "~/assets/images/hipaa-training.svg?component";

import CreditCardIcon from "~/assets/images/creditcard.svg?component";
import CursorIcon from "~/assets/images/cursor.svg?component";
import EnvelopeIcon from "~/assets/images/envelope.svg?component";
import GraduationCapIcon from "~/assets/images/graduationcap.svg?component";
const gtm = useGtm();
const { $lightbox } = useNuxtApp();
const modules = [EffectCreative];
const content = useContentStore();
await content.setup();

const sendGtmEvent = () => {
  try {
    gtm.trackEvent({
      event: "pressed watch intro video",
      category: "engagement",
    });
  } catch (err) {}
};

onMounted(() => {
  $lightbox({
    href: "https://www.youtube.com/watch?v=0o1E5zdYrC0",
    type: "video",
    source: "youtube",
    width: 900,
    autoplayVideos: true,
  });
});

useHead({
  title: "HIPAA training, certification and compliance for $17.95",
  meta: [
    {
      name: "description",
      content:
        "Gain HIPAA compliance through our flexible training programs. Our cost-effective, user-friendly courses encompass all facets of HIPAA. Sign up now!",
    },
  ],
  bodyAttrs: {
    class: "flex h-full flex-col bg-white dark:bg-dark",
  },
  script: [],
});
</script>
