import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 180 173"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M22.793 173.848h136.992c13.301 0 21.035-7.676 21.035-22.559V73.535c0-11.133-1.758-15.937-9.726-21.328l-66.68-45.41C93.809-.47 86.426-.47 75.82 6.797L9.727 52.207C1.817 57.597 0 62.402 0 73.535v77.754c0 14.883 7.793 22.559 22.793 22.559Zm-.234-9.903c-8.086 0-12.657-4.453-12.657-12.773V71.953c0-6.387 1.172-9.258 5.86-12.422l66.797-45.468c5.566-3.868 9.55-3.868 15.175 0L165.06 59.53c4.687 3.106 5.8 6.035 5.8 12.422v79.336c0 8.203-4.511 12.656-12.597 12.656Zm67.793-71.25c-4.688 0-9.024 1.758-13.243 5.625L5.742 162.48l6.797 6.797 70.371-63.339c2.461-2.227 4.863-3.282 7.442-3.282 2.578 0 5.039 1.055 7.5 3.282l70.312 63.339 6.797-6.797-71.309-64.16c-4.218-3.867-8.613-5.625-13.3-5.625Zm-78.165-34.98L5.45 64.57l54.492 54.434 6.797-6.738Zm156.446-.059-54.61 54.61 6.797 6.738 54.61-54.492Z"
    }, null, -1)
  ])))
}
export default { render: render }