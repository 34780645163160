import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 204 211"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M102.188 131.25c6.269.117 12.539-1.465 20.566-5.156l74.824-34.219c4.453-2.05 6.738-5.977 6.738-10.137 0-4.101-2.285-8.144-6.738-10.195l-74.824-34.219c-8.027-3.633-14.297-5.215-20.566-5.156-6.329-.059-12.54 1.523-20.567 5.156L6.797 71.543C2.285 73.593 0 77.637 0 81.738c0 4.16 2.285 8.086 6.797 10.137l74.824 34.219c8.027 3.691 14.238 5.273 20.567 5.156Zm0-10.195c-5.157 0-10.079-1.231-16.641-4.278L14.59 84.375c-1.406-.644-2.11-1.582-2.11-2.637 0-1.113.704-2.05 2.11-2.695l70.957-32.344c6.562-3.047 11.484-4.277 16.641-4.277 5.156 0 10.019 1.23 16.582 4.277l71.015 32.344c1.348.644 2.051 1.582 2.051 2.695 0 1.055-.703 1.993-2.051 2.637l-71.015 32.402c-6.563 3.047-11.426 4.278-16.582 4.278ZM30.41 138.398c0 20.743 27.656 40.137 71.778 40.137 44.062 0 71.718-19.394 71.718-40.137V99.082h-10.371v39.316c0 14.825-23.496 30.293-61.347 30.293-37.852 0-61.348-15.468-61.348-30.293V99.082H30.41Zm72.012-48.515c8.203 0 15.644-3.633 15.644-8.203 0-4.395-7.441-8.028-15.644-8.028-8.262 0-15.762 3.633-15.762 8.028 0 4.57 7.5 8.203 15.762 8.203Zm-46.934 19.629 45.938-23.614-3.574-6.738L46.934 105Zm-8.554 72.656h8.554v-72.656l-8.554-4.219Zm-5.918.586v20.039c0 5.625 3.515 9.141 9.14 9.141h2.051c5.566 0 9.14-3.516 9.14-9.141v-20.039c0-5.566-3.574-9.082-9.14-9.082h-2.05c-5.626 0-9.141 3.516-9.141 9.082Z"
    }, null, -1)
  ])))
}
export default { render: render }